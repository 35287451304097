/******************************* Images Carousel Home *********************************/
import carouselTelas1 from '../img/productos/telas/31.png';
import carouselTelas2 from '../img/productos/telas/32.png';
import carouselTelas3 from '../img/productos/telas/33.png';
import carouselTelas131 from '../img/productos/telas/131.png';
import carouselTelas132 from '../img/productos/telas/132.png';
import carouselTelas133 from '../img/productos/telas/133.png';
import carouselTelas134 from '../img/productos/telas/1.png';

const imgCarouselTelas = [
    [
        carouselTelas1,
        "Pimer portada",

    ],
    [
        carouselTelas2,
        "Segunda portada",
    ],
    [
        carouselTelas3,
        "Tercera portada",
    ],
]

const imgCarouselTelas2 = [
    [
        carouselTelas134,
        "Cuarta portada"
    ],
    [
        carouselTelas131,
        "Pimer portada",

    ],
    [
        carouselTelas132,
        "Segunda portada",
    ],
    [
        carouselTelas133,
        "Tercera portada",
    ],
]

export { imgCarouselTelas, imgCarouselTelas2 }