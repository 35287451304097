import { NavLink } from "react-router-dom"
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";

const CarouselHeader = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };
    
        // Agrega el evento resize al montar el componente
        window.addEventListener("resize", handleResize);
    
        // Limpia el evento al desmontar el componente
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    

    if (screenSize >= 1214){
        return(
            <div id="carouselProduct" className="carousel slide carousel-fade">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/cortinas">Cortinas Metálicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/cortinas/hawainas">Cortinas Hawainas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas">Duelas de&nbsp;Aluminio</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas/accesorios">Duelas y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/motores">Motores</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/mecanismos/percianas/metalicas">Mecanismos&nbsp;para Persiana&nbsp;Metálica</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/elevadores">Elevadores de&nbsp;Parqueo</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/estructuras">Sombrillas Económicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/toldos">Toldos Retráctiles</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/telas" className="specialWidth">Telas para Sombras&nbsp;y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers">Rollers de&nbsp;Exterior</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers/accesorios">Accesorios para&nbsp;Rollers</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/muebles">Muebles Económicos</NavLink>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProduct" data-bs-slide="prev">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProduct" data-bs-slide="next">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }
    if (screenSize >= 900 && screenSize < 1214){
        return(
            <div id="carouselProduct" className="carousel slide carousel-fade">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/cortinas">Cortinas Metálicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/cortinas/hawainas">Cortinas Hawainas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas">Duelas de&nbsp;Aluminio</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas/accesorios">Duelas y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/motores">Motores</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/mecanismos/percianas/metalicas">Mecanismos&nbsp;para Persiana&nbsp;Metálica</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/elevadores">Elevadores de&nbsp;Parqueo</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/estructuras">Sombrillas Económicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/toldos">Toldos Retráctiles</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/telas" className="specialWidth">Telas para Sombras&nbsp;y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers">Rollers de&nbsp;Exterior</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers/accesorios">Accesorios para&nbsp;Rollers</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/muebles">Muebles Económicos</NavLink>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProduct" data-bs-slide="prev">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProduct" data-bs-slide="next">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }

    if (screenSize >= 560 && screenSize < 900){
        return(
            <div id="carouselProduct" className="carousel slide carousel-fade">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/cortinas">Cortinas Metálicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/cortinas/hawainas">Cortinas Hawainas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas">Duelas de&nbsp;Aluminio</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas/accesorios">Duelas y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/motores">Motores</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/mecanismos/percianas/metalicas">Mecanismos&nbsp;para Persiana&nbsp;Metálica</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/elevadores">Elevadores de&nbsp;Parqueo</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/estructuras">Sombrillas Económicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/toldos">Toldos Retráctiles</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/telas" className="specialWidth">Telas para Sombras&nbsp;y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers">Rollers de&nbsp;Exterior</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers/accesorios">Accesorios para&nbsp;Rollers</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/muebles">Muebles Económicos</NavLink>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProduct" data-bs-slide="prev">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProduct" data-bs-slide="next">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }

    if (screenSize < 560){
        return(
            <div id="carouselProduct" className="carousel slide carousel-fade">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/cortinas">Cortinas Metálicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/cortinas/hawainas">Cortinas Hawainas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/duelas">Duelas de&nbsp;Aluminio</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/duelas/accesorios">Duelas y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/motores">Motores</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/mecanismos/percianas/metalicas">Mecanismos&nbsp;para Persiana&nbsp;Metálica</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/elevadores">Elevadores de&nbsp;Parqueo</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/estructuras">Sombrillas Económicas</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/toldos">Toldos Retráctiles</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/telas" className="specialWidth">Telas para Sombras&nbsp;y&nbsp;Accesorios</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers">Rollers de&nbsp;Exterior</NavLink>
                            <span className='fw-bold color-separator'><span>|</span></span>
                            <NavLink to="/rollers/accesorios">Accesorios para Rollers</NavLink>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="d-flex gap-2 align-items-center text-center justify-content-center carouselContent">
                            <NavLink to="/muebles">Muebles Económicos</NavLink>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev new-carousel-control-prev" type="button" data-bs-target="#carouselProduct" data-bs-slide="prev">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowBack size={35}/>
                    </span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next new-carousel-control-next" type="button" data-bs-target="#carouselProduct" data-bs-slide="next">
                    <span className="color-dark-gray" aria-hidden="true">
                        <IoIosArrowForward size={35}/>
                    </span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }
    
}

export default CarouselHeader