import portada from "../img/productos/telas/portada.png";
import { imgCarouselTelas2 } from "../helper/ImagesTelas";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import CotizaOption from "../hooks/CotizaOption";
import { GoDotFill } from "react-icons/go";
import { useEffect } from "react";
import img1 from "../img/productos/telas/10.png";
import img2 from "../img/productos/telas/11.png";
import img3 from "../img/productos/telas/12.png";
import img4 from "../img/productos/telas/13.png";
import img5 from "../img/productos/telas/32.png";
import img6 from "../img/productos/telas/33.png";
import img8 from "../img/productos/telas/31.png";
import img9 from "../img/productos/telas/18.png";
import img10 from "../img/productos/rollers/accesorios/203.png";
import img11 from "../img/productos/rollers/accesorios/204.png";
import img12 from "../img/productos/rollers/accesorios/205.png";
import img13 from "../img/productos/rollers/accesorios/206.png";
import img14 from "../img/productos/rollers/accesorios/207.png";
import img15 from "../img/productos/rollers/accesorios/208.png";
import img16 from "../img/productos/rollers/accesorios/209.png";
import img17 from "../img/productos/rollers/accesorios/210.png";
import img18 from "../img/productos/rollers/accesorios/211.png";
import img19 from "../img/productos/rollers/accesorios/212.png";
import img20 from "../img/productos/rollers/accesorios/213.png";
import img21 from "../img/productos/rollers/accesorios/214.png";
import img22 from "../img/productos/rollers/accesorios/215.png";
import BtnCotiza from "../hooks/BtnCotizar";
import { MdArrowRightAlt } from "react-icons/md";

const Telas = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);


    return (
        <>
            <section className="mb-5">
                <figure>
                    <img src={portada} className="w-100 h-100" alt="Telas Acrílicas"/>
                </figure>
            </section>

            <section className="mb-5 container d-flex justify-content-center container-movil">
                <h2 className="text-uppercase texttitulo text-center w-75 telefono-w-100 title-telas-movil">telas para sombras</h2>
            </section>

            <section className="mb-5 overflow-hidden container-movil">
                <div className="container">
                    <div className="d-flex justify-content-center" data-aos="zoom-in">
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para toldo</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para sombrilla</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para sombra</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para rollers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container" data-aos="flip-left">
                    <h2 className="text-uppercase texttitulo text-center telefono-w-100 title-telas-movil mb-5">telas saran</h2>
                    <div className="d-flex justify-content-around gap-5 container-movil-telas">
                        <div>
                            <figure>
                                <img src={img1} alt="Color verde" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Verde</p>
                        </div>
                        <div>
                            <figure>
                                <img src={img2} alt="Color gris" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Gris</p>
                        </div>
                        <div>
                            <figure>
                                <img src={img3} alt="Color beige" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Beige</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <h2 className="text-uppercase texttitulo text-center telefono-w-100 title-telas-movil mb-5">telas para rollers</h2>
                    <div className="d-flex gap-5 mb-5 flexWrap">
                        <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-right">
                            <div>
                                <figure>
                                    <img src={img4} alt="Tela zebra" className="w-100 h-100"/>
                                </figure>
                                <p className="m-0 text-center py-2 text-white bg-blue">Tela zebra a 2.85 mt de ancho</p>
                                <div className="d-flex mt-2">
                                    <BtnCotiza/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mb-movil-5" data-aos="fade-left">
                            <div>
                                <figure className="d-flex justify-content-end">
                                    <img src={img5} alt="Block Out" className="w-100 h-100"/>
                                </figure>
                                <div className="d-flex justify-content-end">
                                    <p className="m-0 text-center py-2 text-white bg-blue w-100">Block out a 3 mt de ancho</p>
                                </div>
                                <div className="d-flex mt-2 justify-content-end">
                                    <BtnCotiza/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-5 flexWrap">
                        <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-right">
                            <div>
                                <figure>
                                    <img src={img6} alt="Tela screen" className="w-100 h-100"/>
                                </figure>
                                <p className="m-0 text-center py-2 text-white bg-blue">Tela screen de 3 metros de ancho</p>
                                <div className="d-flex mt-2">
                                    <BtnCotiza/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mb-movil-5" data-aos="fade-left">
                            <div>
                                <figure className="d-flex justify-content-end">
                                    <img src={img8} alt="Tela saran" className="w-100 h-100"/>
                                </figure>
                                <div className="d-flex justify-content-end">
                                    <p className="m-0 text-center py-2 text-white bg-blue w-100">Block out 2.50 Mt de ancho</p>
                                </div>
                                <div className="d-flex mt-2 justify-content-end">
                                    <BtnCotiza/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 container d-flex justify-content-center container-movil">
                <h2 className="text-uppercase texttitulo text-center title-telas-movil">tela 100% italiana acrílica</h2>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <div className="d-flex flex-column" data-aos="zoom-in">
                        <div className="mb-3">
                            <h2 className="texttitulo text-center">Beneficios</h2>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Telas acrílicas 100% resistentes a la intemperie.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Cuidado sencillo: Una tela que no teme al descuido.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Tratamiento de resistencia al agua, aceite, manchas y moho.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
               <div className="container">
                    <div className="d-flex justify-content-center" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img9} alt="Tela Acrílica" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center pt-2 text-white bg-blue">Tela acrílica de 1.20 mt de ancho <br></br> 100% italiana</p>
                            <div className="d-flex mt-2">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
               </div>
            </section>

            <section className="daralinicacio container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center etitulo">accesorios para sombras</h2>
            </section>

            <section className="container d-flex gap-3 align-items-center mb-5">
                <span className="texto_tittfica"><MdArrowRightAlt size={50}/></span>
                <h2 className="text-uppercase texto_tittfica">tensores</h2>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR GANCHO ​RUEDA NO. 10 INOX</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="Tensor"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img11} alt="Tensor"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR GANCHO ​GANCHO M10 INOX</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR ABIERTO ABIERTO ​NO 10 INOX</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img12} alt="Tensor"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container d-flex gap-3 align-items-center mb-5">
                <span className="texto_tittfica"><MdArrowRightAlt size={50}/></span>
                <h2 className="text-uppercase texto_tittfica">argollas</h2>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img13} alt="Argolla"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR ARGOLLA ​CON TORNILLO M8 ​INOX</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR TRIANGULO ​8X9.5MM INOX</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img14} alt="Argolla"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img15} alt="Argolla"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR CHUCHO M6 ​INOX</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR ARGOLLA ​MEDIA LUNA INOX M8</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img16} alt="Argolla"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img17} alt="Argolla"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR CHUCHO ​M6 INOX</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">TENSOR MOSQUETON ​M8 INOX</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img18} alt="Argolla"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img19} alt="Tensor"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">CABLE ACERADO 6MM</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">CABLE ACERADO ​5MM FORRADO</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img20} alt="Tensor"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img21} alt="Tensor"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">HILO ACRÍLICO PARA ​COSTURA IRISUN ITALY</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">RIBBON 4CM</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img22} alt="Tensor"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>



            <section className="mb-5 overflow-hidden">
                <div className="container d-flex gap-3 container-movil-telas">
                    {
                        imgCarouselTelas2.map((data, key) => {
                            return <figure key={key}>
                                <img src={data[0]} className="h-100 w-100" alt={data[1]} />
                            </figure>
                        })
                    }
                </div>
            </section>
            

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default Telas