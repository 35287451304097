import portada from "../img/portadas/Rollers.png";
import CotizaOption from "../hooks/CotizaOption";
import img1 from "../img/productos/rollers/62.png";
import img2 from "../img/productos/rollers/61.png";
import img3 from "../img/productos/rollers/60.png";
import img4 from "../img/productos/rollers/63.png";
import video1 from "../img/productos/rollers/1.mp4";
import video2 from "../img/productos/rollers/2.mp4";
import video3 from "../img/productos/rollers/3.mp4";
import rollers from '../pdf/rollers.pdf';
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
//import Catalogo from "./Catalogo";


const Rollers = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section>
                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />

                </figure>
            </section>

            <section className="container daralinicacio overflow-hidden">
                <div className="d-flex justify-content-center bajartt " data-aos="zoom-in">
                    <h2 className="texttitulo text-wrap text-start tptelefono my-3 text-uppercase">ROLLERS DE EXTERIOR</h2>
                </div>

                <div className="container d-flex gap-3 telefono separador daralinicacio overflow-hidden ">
                    <div className=" d-flex gap-3   telefono-reverse " data-aos="fade-right">
                        <div className="imagen_terjeta">
                            <figure className="w-100 h-100">
                                <img
                                    className="w-100 h-100"
                                    src={img1}
                                    alt="Cortinas Métalicas"
                                />
                            </figure>
                        </div>


                        <div className=" w-50 d-flex align-items-center telefono-w-100">
                            <div>
                                <h2 className='text-start texto_tittfica  tptelefono text-capitalize'>
                                    Rollers de PVC
                                </h2>
                                <p className=" cortinasp ">
                                    Ofrece transparencia y protección ​en entornos de baja temperatura
                                    o ​expuestos a condiciones climáticas ​desfavorables.
                                </p>
                                <p className=" cortinasp ">
                                    Pueden adquirirse con sistema ​manual o motorizado.
                                </p>
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container d-flex gap-3 telefono separador  daralinicacio" data-aos="fade-left">

                        <div className=" w-50 d-flex align-items-center telefono-w-100">
                            <div>
                                <h2 className='text-start texto_tittfica tptelefono text-capitalize'>
                                    Rollers tipo Zebra
                                </h2>
                                <p className=" cortinasp ">
                                    Es una combinación de telas ​transparentes y opacas dispuestas
                                    de ​manera intercalada a lo largo de la ​cortina, lo que permite
                                    ajustar entre ​transparencia y privacidad según sea
                                    necesario. Se pueden encontrar con ​mecanismo manual y motorizado.
                                </p>
                                <BtnCotiza/>
                            </div>
                        </div>

                        <div className="imagen_terjeta">
                            <figure className="w-100 h-100">
                                <img
                                    className="w-100 h-100"
                                    src={img2}
                                    alt="Cortinas Métalicas"
                                />
                            </figure>
                        </div>
                    </div>
                </div>




                <div className="container d-flex gap-3 telefono  separador  daralinicacio overflow-hidden">
                    <div className=" d-flex gap-3   telefono-reverse  " data-aos="fade-right">
                        <div className="imagen_terjeta">
                            <figure className="w-100 h-100">
                                <img
                                    className="w-100 h-100"
                                    src={img3}
                                    alt="Cortinas Métalicas"
                                />
                            </figure>
                        </div>


                        <div className=" w-50 d-flex align-items-center telefono-w-100  ">
                            <div>
                                <h2 className='text-start texto_tittfica tptelefono text-capitalize'>
                                    Roller Tipo Black Out
                                </h2 >
                                <p className=" cortinasp  ">
                                    Proporciona total privacidad en ​cualquier ambiente, siendo ideal ​para dormitorios,
                                    salas de ​proyección y espacios que necesiten ​discreción máxima.
                                </p>
                                <p className=" cortinasp  ">
                                    Se encuentran disponibles con ​sistema manual y motorizado.
                                </p>
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="overflow-hidden">
                    <div className="container d-flex gap-3 telefono separador  " data-aos="fade-left">

                        <div className=" w-50 d-flex align-items-center telefono-w-100 ">

                            <div>
                                <h2 className='text-start texto_tittfica tptelefono text-capitalize'>
                                    Roller tipo screen
                                </h2>
                                <p className="  cortinasp ">
                                    Permite el contacto visual con el ​exterior y la entrada de luz natural sin
                                    que esto signifique una pérdida de la ​privacidad, también disminuye el ​calor.
                                </p>
                                <p className="  cortinasp ">
                                    Disponibles con mecanismo
                                    manual y motorizado.
                                </p>
                                <BtnCotiza/>
                            </div>
                        </div>
                        <div className="imagen_terjeta">
                            <figure className="w-100 h-100">
                                <img
                                    className="w-100 h-100"
                                    src={img4}
                                    alt="screen"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </section >

            <section className="mb-5 overflow-hidden">
                <div data-aos="zoom-out">
                    <div className="container d-flex gap-3 content-movil-toldos-videos">
                        <div >
                            <video poster={img3} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>
                        <div >
                            <video poster={img1} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                                <source src={video2} type="video/mp4" />
                            </video>
                        </div>
                        <div >
                            <video poster={img4} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                                <source src={video3} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container d-flex justify-content-center mb-5" data-aos="zoom-in">
                    <NavLink to={rollers} rel="noopener noreferrer nofollow" target="_blank" download="rollers.pdf">
                        <h2 className="roll-cata text-uppercase text-center">descarga ahora nuestro catálogo</h2>
                    </NavLink>
                </div>
            </section>
            
            {/*<section className="mb-5 container">
                <Catalogo secction={'rollers'}/>
            </section>*/}

            <CotizaOption title={'¡Cotiza hoy mismo la tuya!'}/>
        </>
    );
};

export default Rollers;
