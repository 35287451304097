import { IoIosArrowDropright } from "react-icons/io";
import img1 from "../img/productos/muebles/1.png";
import img2 from "../img/productos/muebles/2.png";
import img3 from "../img/productos/muebles/3.png";
import img4 from "../img/productos/muebles/4.png";
import img5 from "../img/productos/muebles/5.png";
import img6 from "../img/productos/muebles/6.png";
import img7 from "../img/productos/muebles/7.png";
import img8 from "../img/productos/muebles/mesa4.png";
import CotizaOption from "../hooks/CotizaOption";
import { NavLink } from "react-router-dom"
import { useEffect } from "react";

const Muebles = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5 container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center text-uppercase">muebles económicos</h2>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="TRILL ARMCHAIR" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="text-uppercase texto_tittfica">SILLA LISSA</h2>
                            <p className="cortinasp">
                                Precio Q.475.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">SILLA CARTI</h2>
                            <p className="cortinasp">
                                Precio Q.475.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img src={img2}  className="w-100 h-100" alt="Cargadores para motor" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Taburete Baro" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">TABURETE BARO</h2>
                            <p className="cortinasp">
                                Precio Q.850.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">SILLA DAGO</h2>
                            <p className="cortinasp">
                                Precio Q.400.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img src={img5}  className="w-100 h-100" alt="Bracket y clutch" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="Silla Flora" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">SILLA FLORA</h2>
                            <p className="cortinasp">
                                Precio Q.1,500.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">COCTELERA BARO</h2>
                            <p className="cortinasp">
                                Precio Q.750.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img src={img6}  className="w-100 h-100" alt="Coctelera Baro" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="Manivela para rollers" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">MESA BARO</h2>
                            <p className="cortinasp">
                                Precio Q.850.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">HY - 03</h2>
                            <p className="cortinasp">
                                Precio Q.1,350.00
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="https://wa.me/+50240831699" rel="noopener noreferrer nofollow" target="_blank">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="position-relative overflow-scroll content-description-scroll">
                            <figure>
                                <img src={img8}  className="w-100 h-100" alt="Bracket y clutch" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default Muebles