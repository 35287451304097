import img1 from "../img/productos/motores/nice/accesorios/24.png";
import img2 from "../img/productos/motores/nice/accesorios/25.png";
import img3 from "../img/productos/motores/nice/accesorios/26.png";
import img4 from "../img/productos/motores/nice/accesorios/27.png";
import img5 from "../img/productos/motores/nice/accesorios/28.png";
import img6 from "../img/productos/motores/nice/accesorios/29.png";
import img7 from "../img/cambn/12.png";
import BtnCotiza from "../hooks/BtnCotizar";
import CotizaOption from "../hooks/CotizaOption";
import { useEffect } from "react";


const MotoresNice = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">motor nice rondon</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="jolly"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2>Características</h2>
                            <p className="mb-0">4mts de cabble 4x16</p>
                            <p className="mb-0">corona 20cm</p>
                            <p className="mb-0">para tubo 2</p>
                            <p className="mb-0">Adaptador para tubo 11/2</p>
                            <p className="mb-0">Capacidad 180KG</p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="nice"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">tarjeta</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">control</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="nice"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="nice"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">botonera kio mini</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">botonera kio</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="nice"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="nice"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">bidi wifi</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">botonera inalambrica 1 canal</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="nice"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default MotoresNice;
