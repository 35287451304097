import portada from "../img/productos/rollers/accesorios/portada.png";
import img17 from "../img/productos/mecanismos_percianas/1.png";
import img18 from "../img/productos/mecanismos_percianas/2.png";
import img19 from "../img/productos/mecanismos_percianas/3.png";
import img20 from "../img/productos/mecanismos_percianas/4.png";
import img21 from "../img/productos/mecanismos_percianas/5.png";
import img22 from "../img/productos/mecanismos_percianas/6.png";
import img23 from "../img/productos/mecanismos_percianas/7.png";
import img24 from "../img/productos/mecanismos_percianas/8.png";
import img25 from "../img/productos/mecanismos_percianas/9.png";
import img26 from "../img/productos/mecanismos_percianas/10.png";
import img27 from "../img/productos/mecanismos_percianas/11.png";
import img28 from "../img/productos/mecanismos_percianas/12.png";
import img29 from "../img/productos/mecanismos_percianas/13.png";
import img30 from "../img/productos/mecanismos_percianas/14.png";
import img31 from "../img/productos/mecanismos_percianas/15.png";
import img32 from "../img/productos/mecanismos_percianas/16.png";
import img33 from "../img/productos/mecanismos_percianas/17.png";
import img34 from "../img/productos/mecanismos_percianas/18.png";
import img35 from "../img/productos/mecanismos_percianas/19.png";
import img36 from "../img/productos/mecanismos_percianas/20.png";
import img37 from "../img/productos/mecanismos_percianas/21.png";
import img38 from "../img/productos/mecanismos_percianas/22.png";
import img39 from "../img/productos/mecanismos_percianas/23.png";
import img40 from "../img/productos/mecanismos_percianas/24.png";
import img41 from "../img/productos/mecanismos_percianas/25.png";
import img42 from "../img/productos/mecanismos_percianas/26.png";
import img43 from "../img/productos/mecanismos_percianas/27.png";
import img44 from "../img/productos/mecanismos_percianas/29.png";
import img45 from "../img/productos/mecanismos_percianas/28.png";
import BtnCotiza from "../hooks/BtnCotizar";
import CotizaOption from "../hooks/CotizaOption";
import { useEffect } from "react";

const RollersAccesorios = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);


    return (
        <>
            <section>
                <figure>
                    <img src={portada} className="w-100 h-100" alt="Portada Accesorios Rollers"/>
                </figure>
            </section>

            <section className="daralinicacio container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center etitulo">accesorios para rollers</h2>
            </section>


            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img17} alt="Faldones"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Faldones ​Blancos</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bracket color ​negro/ roller</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img18} alt="Bracket"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img19} alt="Manivela"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Manivela blanco ​y negro</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Caja reductora ​blanco y negro</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img20} alt="Caja"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img21} alt="Recibidor"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Recibidor de cables ​blanco y negro</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Rueda más ​corona de 70</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img22} alt="Rueda"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img23} alt="Rueda"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Rueda más ​corona de 80</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Kit de manivela (caja ​reductora, manivela, par de ​bracket, rueda nailon y ​adaptadores)</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img24} alt="Kit Manivela"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img24} alt="Bracket"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bracket y Cluth zebra ​38mm</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Cenefa para roller ​de 5.80</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img25} alt="Cenefa"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img26} alt="Bracket"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bracket de zebra a pared</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bottom riel zebra 5.80</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img27} alt="Bottom"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img28} alt="Tapones"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tapones bottom ​zebra</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tape 13mm</h2>
                            <h2 className="fw-bold title-sections">Tape 9mm</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img29} alt="Tape"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img30} alt="Cadena"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Cadena Inox</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Conector Inox largo</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img31} alt="Conector"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img32} alt="Combo"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bracket y clutch ​estándar 38mm</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bottom riel estándar 5.80</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img33} alt="Bottom"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img34} alt="Tapones"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tapones bottom estándar</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Varillas para ​bottom estándar</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img35} alt="Varillas"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img36} alt="Peso"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Peso para cadena</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Ganchos para cadenas</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img37} alt="Ganchos"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img38} alt="Combo"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bracket y Clutch doble ​para screen y block out</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Kit Bracket y Clutch ​Tubo 1.5MM 38MM</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img39} alt="Combo"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img40} alt="Tubo"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tubo galvanizado de ​70mm de 6 metros</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tubo galvanizado de ​80mm de 6 metros</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img41} alt="Tubo"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img42} alt="Tubo"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">TUBO DE 38MM ALUM ​1/12 a 5.80</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">TUBO DE 38MM x 1.5MM ​ALUM 1/12 a 5.80</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img42} alt="Tubo"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img43} alt="Conector"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Conector para ​cadena blanca</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Cadena plástica ​blanca</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img44} alt="Cadena"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden mb-5" >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img45} alt="Tope"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Tope para cadena</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default RollersAccesorios